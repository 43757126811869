<template>
  <!-- App.vue -->

  <v-app>
    <v-app-bar app color="blue" dark>
      <Menu></Menu>
      <v-toolbar-title>Remote Access</v-toolbar-title>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container>

        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
import Menu from "@/components/Menu";

export default {
  name: 'App',
  components: {
    Menu
  }
}
</script>

<style>
</style>
