<template>
  <v-card flat>
    <v-form ref="form">
      <v-select id="customer-select" label="Customer" v-model="selectedCustomer" required :items="customers" item-text="name"></v-select>
      <v-select id="asset-select" label="Asset" v-model="selectedAsset" required :items="assets"></v-select>

      <v-btn id="get-access-btn" v-show="selectedCustomer && selectedAsset" dark absolute right color="blue" @click="clicked" :loading="loading">Get Access</v-btn>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: "NewRemoteAccess",
  data: () => ({
    loading: false,
    valid: true,
    selectedCustomer: undefined,
    selectedAsset: undefined,
    customers: [{
      name: 'MINNESOTA BOARD OF STATE INVESTMENT',
      assets: [
        'HPE MSA 2060',
        'HPE 3PAR StoreServ 7000 Storage'
      ]
    }]
  }),
  computed: {
    assets: function () {
      let items = this.customers.map((customer) => {
        if (this.selectedCustomer === customer.name) {
          return customer.assets;
        }
      });

      if (items) {
        return items[0]
      } else {
        return []
      }
    }
  },
  methods: {
    clicked: function() {
      this.loading = true;

      setTimeout(() => {
        this.$router.push('/RemoteAccess');
      }, 2500);
    }
  }
}
</script>

<style scoped>

</style>
