<template>
  <v-img :src="src"></v-img>
</template>

<script>
export default {
  name: "RemoteAccess",
  data: () => ({
    src: ''
  }),
  mounted() {
    setTimeout(() => {
      this.src = 'https://rdr-it.com/wp-content/uploads/images/02-dashboard-msa.png';
    }, 100);
  }
}
</script>

<style scoped>
  .full-width {
    max-height: 1000px;
    width: 100%;
  }
</style>
