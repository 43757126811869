import VueRouter from 'vue-router';
import NewRemoteAccess from "@/views/NewRemoteAccess";
import RemoteAccess from "@/views/RemoteAccess";

let routes=[
    {
        path: '/New',
        component: NewRemoteAccess
    },
    {
        path: '/RemoteAccess',
        component: RemoteAccess
    }
];

export default new VueRouter({routes});