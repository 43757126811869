<template>
  <v-menu offset-y bottom transition="scroll-y-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-app-bar-nav-icon
          v-bind="attrs"
          v-on="on"
          id="menu-open-btn"
      ></v-app-bar-nav-icon>
    </template>
    <v-list>
      <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="$router.push(item.link)"
          link
          :id="`menu-item-${index}`"
      >
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "Menu",
  data: () => ({
    items: [
      {
        title: 'New Remote Access',
        link: '/New'
      },
      {
        title: 'Existing Remote Access',
        link: '/'
      },
      {
        title: 'Settings',
        link: '/'
      }
    ]
  })
}
</script>

<style scoped>

</style>